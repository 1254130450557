import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const PrivacyPage = () => (
  <Layout>
    <SEO title="個人情報保護方針" />
    <div className="site-container privacy-page">
      <div className="container">
        <h1>個人情報保護方針</h1>
        <div>
          <p>
            Atelier Joeでは、お客様の個人情報に厳重な注意を払い、適切に管理・保護し、お取り扱いいたしてまいります。
          </p>
          <h2>個人情報の取得と利用目的について</h2>

          <p>
            お客様からのお問い合わせなどにより氏名、住所、電話番号、メールアドレスなどお客様の個人情報を取得させていただく場合がございます。<br />
            また、取得する場合はあらかじめその利用目的を公表します。ただし、以下の場合はこの限りではありません。
            </p>
          <ul>
            <li>利用目的をご本人に通知し、または公表することによりご本人または第三者の生命、身体、財産その他の 権利利益を害するおそれがある場合</li>
            <li>法令に基づき開示・提供を求められた場合</li>
            <li>人の生命、身体、財産の保護に必要であって、お客様の同意を得るのが困難な場合</li>
            <li>利用目的の達成に必要であって、適切な情報保護・管理を行っている委託先・提携先に委託する場合</li>
            <li>取得の状況からみて利用目的が明らかであると認められる場合</li>
          </ul>

          <h2>個人情報の管理について</h2>
          <p>
            お客様から取得させていただいた個人情報は厳重に管理し、セキュリティの強化に努めます。
            また、お客様の同意がない限り、第三者への開示・提供を行うことはございません。
          </p>
          <h2>個人情報の開示、訂正等について</h2>
          <p>
            お客様からの個人情報の開示・訂正・削除などのご要請に対応いたします。<br />
            ご要請がお客様本人であることを確認の上、合理的な範囲で対応させていただきます。<br />
            取り扱いに関してご不明点や苦情の申し出などは、当サイトのお問い合わせフォームよりご連絡くださいますようお願いいたします。
          </p>
          <h2>Webサイトにおける、SSLとクッキー・JavaScriptの使用について</h2>
          <p>
            お客様の個人情報を守るためにSSL（Secure Socket Layer）を使用しています。SSLはお客様の個人情報等を暗号化通信で取り扱う技術です。
            また、本サイトでお客様を識別するためにクッキー（Cookie）およびJavaScriptを利用しております。お使いのブラウザでご利用が可能であることをご確認ください。
          </p>
          <h2>お客様の同意について</h2>
          <p>
            個人情報の取り扱いについて上記内容に同意いただけていることを前提として、サービスを提供しております。 また、お客様へのサービス向上のため予告なく内容変更および改訂されることがあります。この場合も同意していただけたとみなされますのでご了承ください。
          </p>
          <h2>お問い合わせ窓口</h2>
          <p>
            ご意見、ご質問、その他利用者情報の取扱いに関するお問い合わせは下記メール宛にお願いいたします。（アットマークを半角に変えてお送りください）
          </p>
          <p>
            zb185423＠yahoo.co.jp          
          </p>
          <h2>本方針の適用範囲</h2>
          <p>下記のWebサイト、アプリケーションが本方針の適用範囲となります。</p>
          <ul>
            <li>
              <a title="Atelier Joe" href="/">本Webサイト</a>
            </li>
            <li>
              <a title="Google Play Store" target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/developer?id=zb185423">GooglePlayストアでデベロッパー[zb185423]が配布しているモバイルアプリ</a>
            </li>
            <li>
              <a title="App Store" target="_blank" rel="noreferrer" href="https://apps.apple.com/us/developer/yuzuru-watanabe/id1564714966">App Storeで[yuzuru watanabe]が配布しているモバイルアプリ</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Layout >
);

export default PrivacyPage;
